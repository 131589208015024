"use client";
import React, { useState } from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
} from "react-places-autocomplete";
import { Popover } from "@mantine/core";
import { colours } from "../../utils/dataList/colours";
import styles from "./search.module.css";

export default function GoogleGeoSearch() {
    const [address, setAddress] = React.useState("");
    const [opened, setOpened] = useState(false);

    const handleSelect = async (address: string, _placeId: string) => {
        const results = await geocodeByAddress(address);
        const isCountry = address.split(",").length === 1;

        setOpened(false);

        if (isCountry) {
            // redirect to dedicated country page
            const formattedAddress =
                results[0].address_components[
                    results[0].address_components.length - 1
                ].long_name;
            window.location.href = `/country/${encodeURIComponent(
                formattedAddress
            )}`;
        } else {
            // non-country location
            window.location.href = `/search/${encodeURIComponent(address)}`;
        }
    };

    return (
        <>
            <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                }) => (
                    <Popover
                        position="bottom"
                        shadow="sm"
                        opened={opened}
                        onChange={setOpened}
                    >
                        <Popover.Target>
                            <input
                                {...getInputProps({
                                    placeholder: "Where to?",
                                })}
                                className={styles.searchbar}
                                onInput={() => setOpened(true)}
                            />
                        </Popover.Target>
                        <Popover.Dropdown>
                            {loading ? <div>...loading</div> : null}
                            {suggestions.map((suggestion: any) => {
                                const style = {
                                    backgroundColor: suggestion.active
                                        ? `${colours.primary}`
                                        : "transparent",
                                };

                                const { key, ...suggestionProps } = {
                                    ...getSuggestionItemProps(suggestion),
                                    style,
                                    key: suggestion.placeId,
                                };

                                return (
                                    <div key={key} {...suggestionProps}>
                                        {suggestion.description}
                                    </div>
                                );
                            })}
                        </Popover.Dropdown>
                    </Popover>
                )}
            </PlacesAutocomplete>
        </>
    );
}
