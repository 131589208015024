"use client";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { currencyConversion } from "../../utils/apiFunctions/listingFunction";
import { NumberFormatter, Loader } from "@mantine/core";
import styles from "./exp.module.css";

interface ExpItem {
    base_currency: string;
    price: number;
    iscustom_price: boolean;
    price_text: string;
    style: "card" | "full";
}

function ListingPriceCard({
    base_currency,
    price,
    iscustom_price,
    price_text,
    style,
}: ExpItem) {
    const isCustomPrice = iscustom_price;
    const targetCurrency = localStorage?.getItem("userCurrencyCode") || "GBP";
    const userCurrencySign = localStorage?.getItem("userCurrencySign") || "£";
    const baseCurrency = base_currency?.toUpperCase();
    const fromCurrencyString = `USD${baseCurrency}`;
    const toCurrencyString = `USD${targetCurrency}`;
    const isSameCurrency = targetCurrency === baseCurrency;

    const { isLoading, isError, data } = useQuery({
        queryKey: ["currencyConversion", fromCurrencyString, toCurrencyString],
        queryFn: ({ queryKey }) =>
            currencyConversion(queryKey[1] as string, queryKey[2] as string),
        enabled: !isCustomPrice && !isSameCurrency,
    });

    if (isLoading) return <Loader color="#000" size="xs" />;
    if (isError) return null;

    const fromRate =
        data?.find(
            (rate: { currency: string }) => rate.currency === fromCurrencyString
        )?.rate || 1;
    const toRate =
        data?.find(
            (rate: { currency: string }) => rate.currency === toCurrencyString
        )?.rate || 1;

    return (
        <>
            {isCustomPrice ? (
                <p
                    className={
                        style === "card"
                            ? styles.pricelabel
                            : styles.overview_label
                    }
                >
                    <span
                        className={
                            style === "card"
                                ? styles.pricelabel
                                : styles.spantext
                        }
                    >
                        {"🏷️"} From:{" "}
                    </span>
                    {price_text}
                </p>
            ) : (
                <p
                    className={
                        style === "card"
                            ? styles.pricelabel
                            : styles.overview_label
                    }
                >
                    <span
                        className={
                            style === "card"
                                ? styles.pricelabel
                                : styles.spantext
                        }
                    >
                        {"🏷️"} From:{" "}
                    </span>
                    {userCurrencySign}{" "}
                    <NumberFormatter
                        value={
                            baseCurrency !== targetCurrency
                                ? price * (toRate / fromRate)
                                : price
                        }
                        thousandSeparator
                        decimalScale={2}
                    />
                </p>
            )}
        </>
    );
}

export default React.memo(ListingPriceCard);
