import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/vercel/path0/lib/QueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/vercel/path0/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/vercel/path0/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
