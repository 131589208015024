"use client";
import React, { useState } from "react";
import { Modal } from "@mantine/core";
import { useRouter } from "next/navigation";
import SearchModal from "../search/SearchModal";
import MagGlass from "../icons/magGlass";
import { miniIconSize } from "../../utils/iconsize";
import PrimaryButton, { SeeAllOutlineButton } from "../resuables/Button";
import styles from "./index.module.css";

export default function SearchGroup() {
    const [opened, setOpened] = useState(false);
    const router = useRouter();
    return (
        <section className={styles.searchgroup}>
            <SeeAllOutlineButton
                rightSection={
                    <MagGlass
                        height={miniIconSize.height}
                        width={miniIconSize.width}
                        size={miniIconSize.size}
                    />
                }
                onClick={() => setOpened(true)}
            >
                Where to?
            </SeeAllOutlineButton>
            <PrimaryButton onClick={() => router.push("/#1")}>
                Top destinations
            </PrimaryButton>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                radius="15px"
            >
                <SearchModal />
            </Modal>
        </section>
    );
}
