import { Skeleton } from "@mantine/core";
import styles from "./resuables.module.css";

export default function SkeletonLoaderHero() {
    return (
        <div>
            <Skeleton
                height={320}
                classNames={{ root: styles.skeletonheroroot }}
            />
        </div>
    );
}

export function SkeletonLoaderPage() {
    return (
        <div>
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                width="70%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={0.1}
                animate={false}
                classNames={{ root: styles.skeletonpageholderroot }}
            />
        </div>
    );
}

export function SkeletonLoaderCard() {
    return (
        <div>
            <Skeleton
                height={210}
                width={280}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={25}
                width={280}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={25}
                width={280}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={25}
                width={280}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={0.1}
                width="1%"
                mt="145px"
                classNames={{ root: styles.skeletonpageroot }}
            />
        </div>
    );
}
export function SmallSkeletonLoaderCard() {
    return (
        <div>
            <Skeleton
                height={120}
                width={200}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={20}
                width={200}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={20}
                width={150}
                classNames={{ root: styles.skeletonpageroot }}
            />
        </div>
    );
}

export function SkeletonLoaderCardGroup() {
    return (
        <>
            <div>
                <Skeleton
                    height={40}
                    width={280}
                    mb="25px"
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={40}
                    width={280}
                    mb="25px"
                    classNames={{ root: styles.skeletonpageroot }}
                />

                <Skeleton
                    height={210}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={200}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={0.1}
                    width="1%"
                    mt="160px"
                    classNames={{ root: styles.skeletonpageroot }}
                />
            </div>
            <div>
                <Skeleton
                    height={40}
                    width={280}
                    mb="25px"
                    classNames={{ root: styles.skeletonpageroot }}
                />

                <Skeleton
                    height={210}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={20}
                    width={200}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={0.1}
                    width="1%"
                    mt="160px"
                    classNames={{ root: styles.skeletonpageroot }}
                />
            </div>
            <div>
                <Skeleton
                    height={40}
                    width={280}
                    mb="25px"
                    classNames={{ root: styles.skeletonpageroot }}
                />

                <Skeleton
                    height={210}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={20}
                    width={200}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={0.1}
                    width="1%"
                    mt="160px"
                    classNames={{ root: styles.skeletonpageroot }}
                />
            </div>
        </>
    );
}
export function SkeletonLoaderExpPage() {
    return (
        <div className={styles.exppageskeleton}>
            <Skeleton
                height={40}
                width={280}
                mb="15px"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                width={200}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                width={200}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={250}
                width={280}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={100}
                width={280}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={30}
                width={280}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={30}
                width={280}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={30}
                width={210}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton height={10} width="100%" animate={false} mt="500px" />
        </div>
    );
}

export function SkeletonLoaderCardGroupSingle() {
    return (
        <>
            <div>
                <Skeleton
                    height={40}
                    width={280}
                    mb="25px"
                    classNames={{ root: styles.skeletonpageroot }}
                />

                <Skeleton
                    height={210}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={280}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={22}
                    width={200}
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={0.1}
                    width="1%"
                    mt="160px"
                    classNames={{ root: styles.skeletonpageroot }}
                />
            </div>
        </>
    );
}
