import Image from "next/image";
import { Card } from "@mantine/core";
import ListingAction from "./ListingAction";
import ListingPriceCard from "./ListingPriceCard";
import TripAdvisorRating from "./TripAdvisorRating";
import styles from "./exp.module.css";

interface ListingCardItem {
    feature_img: string;
    destination: string;
    title: string;
    price: number;
    base_currency: string;
    slug: string;
    id: number;
    iscustom_price: boolean;
    campaign: string;
    price_text: string;
    overview: string;
    tp_review_rating: number;
    tp_review_num: number;
    is_custom_operator: boolean;
}

export default function ListingCard(props: ListingCardItem) {
    return (
        <>
            <Card className={styles.card}>
                <Card.Section component="a" href={`/experience/${props.slug}`}>
                    <Image
                        alt={props.title}
                        src={props.feature_img}
                        width={280}
                        height={210}
                    />
                </Card.Section>

                <Card.Section
                    className={styles.cardinfo}
                    component="a"
                    href={`/experience/${props.slug}`}
                >
                    <h4 className={styles.cardtitle}>{props.title}</h4>

                    <h5 className={styles.cardlabel}>{props.destination}</h5>

                    <ListingPriceCard
                        base_currency={props.base_currency}
                        price={props.price}
                        iscustom_price={props.iscustom_price}
                        price_text={props.price_text}
                        style="card"
                    />
                </Card.Section>

                <Card.Section className={styles.cardaction}>
                    <div className={styles.cardactiongroup}>
                        {props.tp_review_rating > 0 ? (
                            <TripAdvisorRating
                                tp_review_rating={props.tp_review_rating}
                                tp_review_num={props.tp_review_num}
                            />
                        ) : null}
                        <ListingAction
                            title={props.title}
                            feature_img={props.feature_img}
                            overview={props.overview}
                            slug={props.slug}
                            listing_id={props.id}
                            is_custom_operator={props.is_custom_operator}
                        />
                    </div>
                </Card.Section>
            </Card>
        </>
    );
}
