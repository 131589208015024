"use client";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { Card } from "@mantine/core";
import { SecondaryOutlineButton } from "../lego/Button";
import styles from "./card.module.css";

export default function PartnershipCard() {
    const router = useRouter();
    return (
        <>
            <h3 className={styles.sectionHeading}>Join us as partners</h3>
            <section className={styles.partnercardgroup}>
                <Card className={styles.partnercard}>
                    <Card.Section>
                        <Image
                            src="https://kodama-web-asset.s3.eu-west-2.amazonaws.com/general/creatorcard.jpg"
                            alt="Kodama Creator programme"
                            width={150}
                            height={280}
                            unoptimized
                        />
                    </Card.Section>
                    <section className={styles.cardinfo}>
                        <h4 className={styles.blogcardtitle}>Creators</h4>
                        <p className={styles.cardcontent}>
                            Join our Creator Programme and share you crafted
                            contents with our audience.
                        </p>
                        <SecondaryOutlineButton
                            onClick={() => router.push("/creator")}
                        >
                            Learn more
                        </SecondaryOutlineButton>
                    </section>
                </Card>
                <Card className={styles.partnercard}>
                    <Card.Section>
                        <Image
                            src="https://kodama-web-asset.s3.eu-west-2.amazonaws.com/general/suppliercard.jpg"
                            alt="Kodama supplier programme"
                            width={150}
                            height={280}
                            unoptimized
                        />
                    </Card.Section>

                    <section className={styles.cardinfo}>
                        <h4 className={styles.blogcardtitle}>Suppliers</h4>
                        <p className={styles.cardcontent}>
                            Become a Kodama supplier and showcase your
                            responsible travle experiences.
                        </p>
                        <SecondaryOutlineButton
                            onClick={() => router.push("/supplier")}
                        >
                            Learn more
                        </SecondaryOutlineButton>
                    </section>
                </Card>
            </section>
        </>
    );
}
