"use client";
import { Text } from "@mantine/core";

import SearchGroup from "./SearchGroup";
import styles from "./index.module.css";

export default function Hero() {
    return (
        <>
            <section className={styles.root}>
                <div className={styles.content}>
                    <h1 className={styles.title}>
                        Make{" "}
                        <Text
                            component="span"
                            style={{ color: "var(--primary-color)" }}
                            inherit
                        >
                            Responsible Travel
                        </Text>{" "}
                        the norm
                    </h1>

                    <h2 className={styles.description}>
                        {`We make it easy to book responsible travel experiences. Founded by conservationists, powered by ethical tour operators. We use travel to fulfill a`}{" "}
                        <span className={styles.spantext}>
                            <a href="/conservation">conservation mission.</a>
                        </span>{" "}
                    </h2>

                    <SearchGroup />
                </div>
            </section>
        </>
    );
}
