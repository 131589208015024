import { useState } from "react";
import { Tooltip, Modal } from "@mantine/core";
import Pencil from "../icons/pencil";
import { ActionIconButtonKT } from "../lego/Button";
import { smallIconSize } from "../../utils/dataList/iconsize";
import styles from "./exp.module.css";

export default function CustomButton({
    is_custom_operator,
}: {
    is_custom_operator: boolean;
}) {
    const [opened, setOpened] = useState(false);

    return (
        <>
            {is_custom_operator ? (
                <Tooltip label="This experience can be customised">
                    <ActionIconButtonKT onClick={() => setOpened(true)}>
                        <Pencil
                            width={smallIconSize.width}
                            height={smallIconSize.height}
                            size={smallIconSize.size}
                        />
                    </ActionIconButtonKT>
                </Tooltip>
            ) : null}
            <Modal
                opened={opened}
                radius="md"
                size="auto"
                onClose={() => setOpened(false)}
            >
                <section className={styles.overviewModal}>
                    <h5 className={styles.overviewModalTitle}>Custom Tours</h5>
                    <p>
                        {`This itinerary is part of our custom tours. Let us know in the booking form if and how you'd like to personalise the itinerary.`}
                    </p>
                </section>
            </Modal>
        </>
    );
}
