"use client";
import React, { useState } from "react";
import { Modal } from "@mantine/core";
import SearchModal from "../search/SearchModal";
import MagGlass from "../icons/magGlass";
import { miniIconSize } from "../../utils/dataList/iconsize";
import PrimaryButton, { ClearOutlineButton } from "../lego/Button";
import styles from "./search.module.css";

export default function SearchGroup() {
    const [opened, setOpened] = useState(false);

    return (
        <section className={styles.searchgroup}>
            <ClearOutlineButton
                rightsection={
                    <MagGlass
                        height={miniIconSize.height}
                        width={miniIconSize.width}
                        size={miniIconSize.size}
                    />
                }
                onClick={() => setOpened(true)}
            >
                Where to?
            </ClearOutlineButton>
            <PrimaryButton
                onClick={() => {
                    const element = document.getElementById("destinations");
                    if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                    }
                }}
            >
                Top destinations
            </PrimaryButton>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                radius="15px"
            >
                <SearchModal />
            </Modal>
        </section>
    );
}
