"use client";

import { useState } from "react";
import { useRouter } from "next/navigation";
import { useSession, signOut } from "next-auth/react";
import { Menu, MenuDivider, Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Bar from "../icons/bars";
import ProfileAvatar from "../account/profile/ProfileAvatar";
import SignIn from "./SignIn";
import DestinationModal from "./DestinationModal";
import ExperienceModal from "./ExperienceModal";
import { smallIconSize } from "../../utils/dataList/iconsize";
import styles from "./header.module.css";

export default function UserMenu() {
    const [opened, setOpened] = useState(false);
    const { data: session, status } = useSession();
    const [regionOpen, setRegionOpen] = useState(false);
    const [openedE, setEOpened] = useState(false);
    const [countryOpen, setCountryOpen] = useState(false);
    const isBigScreen = useMediaQuery("(min-width: 768px)");
    const router = useRouter();

    const handleSignOut = async (e: any) => {
        await signOut({ redirect: false });
        window.location.reload();
    };

    const handleRegionClick = () => {
        setRegionOpen(false);
        setCountryOpen(true);
    };

    return (
        <>
            {status === "authenticated" && isBigScreen && (
                <p className={styles.greeting}>
                    Hi {session?.user?.name?.split(" ")[0]}
                </p>
            )}
            <Menu
                shadow="md"
                width={200}
                classNames={{ item: styles.menu_text }}
                aria-label="User menu"
            >
                <Menu.Target>
                    <div className={styles.menucircle}>
                        <ProfileAvatar />
                        <Bar
                            height={smallIconSize.width}
                            width={smallIconSize.height}
                            size={smallIconSize.size}
                        />
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    {status === "authenticated" && (
                        <Menu.Item
                            component="a"
                            onClick={() =>
                                (window.location.href = `/account/${session?.user?.email}`)
                            }
                        >
                            Account
                        </Menu.Item>
                    )}
                    {status === "authenticated" ? (
                        <Menu.Item
                            component="button"
                            type="submit"
                            onClick={handleSignOut}
                        >
                            Sign Out
                        </Menu.Item>
                    ) : (
                        <Menu.Item onClick={() => setOpened(true)}>
                            Sign in
                        </Menu.Item>
                    )}

                    <MenuDivider />

                    <Menu.Item onClick={() => setRegionOpen((o) => !o)}>
                        Destination
                    </Menu.Item>
                    <Menu.Item onClick={() => setEOpened((o) => !o)}>
                        Experience
                    </Menu.Item>
                    <Menu.Item
                        component="a"
                        onClick={() => router.push("/collection")}
                    >
                        Collection
                    </Menu.Item>
                    <Menu.Item
                        component="a"
                        onClick={() => router.push("/custom-tour")}
                    >
                        Tailor-made Tour
                    </Menu.Item>
                    <MenuDivider />

                    <Menu.Item onClick={() => router.push("/about")}>
                        About
                    </Menu.Item>
                    <Menu.Item onClick={() => router.push("/blog")}>
                        Blog
                    </Menu.Item>

                    <Menu.Item onClick={() => router.push("/contact")}>
                        Contact Us
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <Modal
                opened={opened}
                size="auto"
                radius="sm"
                onClose={() => setOpened(false)}
            >
                <SignIn />
            </Modal>
            <Modal
                opened={regionOpen}
                size="auto"
                radius="sm"
                onClose={handleRegionClick}
            >
                <DestinationModal />
            </Modal>
            <Modal
                opened={openedE}
                size="auto"
                radius="sm"
                onClose={() => setEOpened(false)}
            >
                <ExperienceModal />
            </Modal>
        </>
    );
}
