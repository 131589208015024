"use client";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useQuery } from "@tanstack/react-query";
import { getFeaturedCountry } from "../../utils/apiFunctions/placeFunction";
import { SmallSkeletonLoaderCard } from "../lego/SkeletonLoader";
import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import styles from "./card.module.css";

interface CountryItems {
    country_name: string;
    card_img: string;
}

export default function FeaturedCountries() {
    const router = useRouter();
    const { data, isLoading, isError } = useQuery({
        queryKey: ["countryList"],
        queryFn: () => getFeaturedCountry(),
    });

    if (isLoading) return <SmallSkeletonLoaderCard />;
    if (isError) return null;

    const sortedCountry: CountryItems[] = data.sort(
        (a: CountryItems, b: CountryItems) =>
            a.country_name.localeCompare(b.country_name)
    );
    return (
        <>
            <h3 id="destinations" className={styles.sectionHeading}>
                Featured Destinations
            </h3>

            <Carousel
                slideGap="sm"
                align="start"
                slideSize="12%"
                slidesToScroll={2}
                loop
                classNames={{ controls: styles.controls }}
            >
                {sortedCountry.map((country: CountryItems) => (
                    <Carousel.Slide key={country.card_img}>
                        <div
                            onClick={() =>
                                router.push(
                                    `/country/${encodeURIComponent(
                                        country.country_name
                                    )}`
                                )
                            }
                            className={styles.featureCard}
                            key={country.country_name}
                        >
                            <Image
                                src={country.card_img}
                                alt={country.country_name}
                                width={150}
                                height={200}
                                unoptimized
                                className={styles.featureImage}
                            />

                            <h4 className={styles.featureHeading}>
                                {country.country_name}
                            </h4>
                        </div>
                    </Carousel.Slide>
                ))}
            </Carousel>
        </>
    );
}
