import React from "react";
import ArrorwLongLeft from "../icons/arrowlongleft";
import { smallIconSize } from "../../utils/iconsize";
import { Button, ActionIcon, Badge } from "@mantine/core";
import styles from "./resuables.module.css";

const PrimaryButton = ({ children, ...rest }: any) => (
    <Button
        autoContrast
        classNames={{
            root: styles.primaryButtonRoot,
            label: styles.primaryButtonLabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);

export default PrimaryButton;

const SecondaryFilledButton = ({ children, ...rest }: any) => (
    <Button
        autoContrast
        classNames={{
            root: styles.secondaryButtonRoot,
            label: styles.secondaryButtonLabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { SecondaryFilledButton };

const MobileBookingButton = ({ children, ...rest }: any) => (
    <Button
        autoContrast
        classNames={{
            root: styles.bookingButtonRoot,
            label: styles.bookingButtonLabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { MobileBookingButton };

const SecondaryOutlineButton = ({ children, ...rest }: any) => (
    <Button
        autoContrast
        variant="outline"
        classNames={{
            root: styles.secondaryOutlineButtonRoot,
            label: styles.secondaryOutlineButtonLabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { SecondaryOutlineButton };

const OutlineAlertButton = ({ children, ...rest }: any) => (
    <Button
        autoContrast
        variant="outline"
        classNames={{
            root: styles.outlineAlertButtonRoot,
            label: styles.outlineAlertButtonLabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { OutlineAlertButton };

const TabButton = ({ children, ...rest }: any) => (
    <Button
        variant="subtle"
        classNames={{
            root: styles.tabbuttonroot,
            label: styles.tablabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { TabButton };

const SubtleButtonKT = ({ children, ...rest }: any) => (
    <Button
        variant="subtle"
        classNames={{
            root: styles.subtlebuttonroot,
            label: styles.tablabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { SubtleButtonKT };

const ClearOutlineButton = ({ children, ...rest }: any) => (
    <Button
        autoContrast
        variant="outline"
        classNames={{
            root: styles.clearOutlineButtonRoot,
            label: styles.clearOutlineButtonLabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { ClearOutlineButton };

const SeeAllOutlineButton = ({ children, ...rest }: any) => (
    <Button
        autoContrast
        variant="outline"
        classNames={{
            root: styles.SeeallOutlineButtonRoot,
            label: styles.SeeallOutlineButtonLabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { SeeAllOutlineButton };

const FilterActionButton = ({ children, ...rest }: any) => (
    <ActionIcon
        autoContrast
        variant="outline"
        size="lg"
        classNames={{
            root: styles.filterbuttonroot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);
export { FilterActionButton };

const ActionIconButtonKT = ({ children, ...rest }: any) => (
    <ActionIcon
        variant="subtle"
        classNames={{
            root: styles.actionbuttonroot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);
export { ActionIconButtonKT };

const ActionIconAlertButtonKT = ({ children, ...rest }: any) => (
    <ActionIcon
        variant="subtle"
        classNames={{
            root: styles.alertbuttonroot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);
export { ActionIconAlertButtonKT };

const CountActionIconKT = ({ children, ...rest }: any) => (
    <ActionIcon
        size="sm"
        classNames={{
            root: styles.countIconRoot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);

export { CountActionIconKT };

const FavActionIconButtonKT = ({ children, ...rest }: any) => (
    <ActionIcon
        color="#C91A52"
        classNames={{
            root: styles.favroot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);
export { FavActionIconButtonKT };

const BadgeKT = ({ children, ...rest }: any) => (
    <Badge
        color="#C91A52"
        classNames={{
            root: styles.badgeroot,
            label: styles.badgelabel,
        }}
        {...rest}
    >
        {children}
    </Badge>
);
export { BadgeKT };

const BackButton = ({ children, ...rest }: any) => (
    <Button
        variant="transparent"
        leftSection={
            <ArrorwLongLeft
                width={smallIconSize.width}
                height={smallIconSize.height}
                size={smallIconSize.size}
            />
        }
        classNames={{
            root: styles.breadcrumbroot,
            label: styles.breadcrumblabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { BackButton };

const UnstyledButtonKT = ({ children, ...rest }: any) => (
    <Button
        variant="transparent"
        classNames={{
            root: styles.unstyledbuttonroot,
            label: styles.unstyledbuttonlabel,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { UnstyledButtonKT };
