import axios from "axios";

export const getCountryInfo = async (country: string) => {
    const response = await axios.get(
        `/api/country/countryInfo?country=${encodeURIComponent(country)}`
    );
    return response.data.countryInfo;
};

export const getCountryListing = async (country: string) => {
    const response = await axios.get(
        `/api/country/countryListing?country=${encodeURIComponent(country)}`
    );
    return response.data.countryListing;
};
export const getFeaturedCountry = async () => {
    const response = await axios.get(`/api/country/featureCountry`);
    return response.data.countryList;
};

export const getCustomCountry = async () => {
    const response = await axios.get(`/api/country/customCountry`);
    return response.data.customCountry;
};

export const getFullCountryListing = async (country: string) => {
    const response = await axios.get(
        `/api/country/countryListingFull?country=${encodeURIComponent(country)}`
    );
    return response.data.fullCountryListing;
};

export const getCityCard = async (country: string) => {
    const response = await axios.get(
        `/api/city/cityCard?country=${encodeURIComponent(country)}`
    );
    return response.data.cityCard;
};

export const getCityHero = async (country: string, city: string) => {
    const response = await axios.get(
        `/api/city/cityHero?country=${encodeURIComponent(
            country
        )}&city=${encodeURIComponent(city)}`
    );
    return response.data.cityHero;
};

export const getCityListing = async (country: string, city: string) => {
    const response = await axios.get(
        `/api/city/cityListing?country=${encodeURIComponent(
            country
        )}&city=${encodeURIComponent(city)}`
    );
    return response.data.cityListing;
};

export const getFeaturedCity = async () => {
    const response = await axios.get(`/api/city/featureCity`);
    return response.data.cityList;
};

export const getFullDestination = async () => {
    const response = await axios.get(`/api/country/fullDestination`);
    return response.data.countryList;
};

export const getCityWeather = async (weather_code: string) => {
    const response = await axios.get(
        `/api/city/weather?weather_code=${weather_code}`
    );
    return response.data.cityWeather;
};
