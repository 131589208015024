import { Rating, NumberFormatter } from "@mantine/core";
import Image from "next/image";
import styles from "./exp.module.css";

interface expItems {
    tp_review_rating: number;
    tp_review_num: number;
}

export default function TripAdvisorRating(props: expItems) {
    return (
        <div className={styles.rating}>
            <Image
                src="/logo/tripadvisor.webp"
                alt="trip advisor"
                height={22}
                width={22}
                unoptimized
            />
            <Rating
                value={props.tp_review_rating}
                color="#00AA6C"
                size="xs"
                fractions={2}
                readOnly
            />
            <p className={styles.ratinglabel}>
                (
                <NumberFormatter
                    value={props.tp_review_num}
                    thousandSeparator
                />
                )
            </p>
        </div>
    );
}
