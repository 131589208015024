"use client";
import { useState } from "react";
import { useRouter } from "next/navigation";
import { Popover, Text } from "@mantine/core";
import DestinationTab from "./DestinationTab";
import ExperienceTab from "./ExperienceTab";
import CurrencySelector from "./CurrencySelector";
import HeaderGeoSearch from "./HeaderGeoSearch";
import ToggleTheme from "../toggleTheme/ToggleTheme";
import UserMenu from "./HeaderMenu";
import ChevDown from "../icons/chevdown";
import ChevUp from "../icons/chevup";
import { ClearButton } from "../lego/Button";
import { miniIconSize } from "../../utils/dataList/iconsize";
import styles from "./header.module.css";

export default function Header() {
    const router = useRouter();
    const [openedD, setDOpened] = useState(false);
    const [openedE, setEOpened] = useState(false);

    const toggleDestinationTab = () => {
        setDOpened(!openedD);
        setEOpened(false);
    };

    const toggleExperienceTab = () => {
        setDOpened(false);
        setEOpened(!openedE);
    };

    return (
        <header className={styles.header}>
            <Text className={styles.logotext} onClick={() => router.push("/")}>
                Kodama Travel
            </Text>

            <Popover
                position="bottom"
                withArrow
                opened={openedD}
                onChange={setDOpened}
                classNames={{ dropdown: styles.popoverdropdown }}
            >
                <Popover.Target>
                    <ClearButton
                        visibleFrom="sm"
                        onClick={toggleDestinationTab}
                        rightSection={
                            openedD ? (
                                <ChevDown
                                    height={miniIconSize.width}
                                    width={miniIconSize.height}
                                    size={miniIconSize.size}
                                />
                            ) : (
                                <ChevUp
                                    height={miniIconSize.width}
                                    width={miniIconSize.height}
                                    size={miniIconSize.size}
                                />
                            )
                        }
                    >
                        Destination
                    </ClearButton>
                </Popover.Target>
                <Popover.Dropdown>
                    <DestinationTab />
                </Popover.Dropdown>
            </Popover>

            <Popover
                width={700}
                position="bottom"
                withArrow
                shadow="sm"
                opened={openedE}
                onChange={setEOpened}
                classNames={{ dropdown: styles.popoverdropdown }}
            >
                <Popover.Target>
                    <ClearButton
                        visibleFrom="sm"
                        onClick={toggleExperienceTab}
                        rightSection={
                            openedE ? (
                                <ChevDown
                                    height={miniIconSize.width}
                                    width={miniIconSize.height}
                                    size={miniIconSize.size}
                                />
                            ) : (
                                <ChevUp
                                    height={miniIconSize.width}
                                    width={miniIconSize.height}
                                    size={miniIconSize.size}
                                />
                            )
                        }
                    >
                        Experience
                    </ClearButton>
                </Popover.Target>

                <Popover.Dropdown>
                    <ExperienceTab />
                </Popover.Dropdown>
            </Popover>

            <ClearButton
                visibleFrom="md"
                onClick={() => router.push("/collection")}
            >
                Collection
            </ClearButton>
            <ClearButton
                visibleFrom="md"
                onClick={() => router.push("/custom-tour")}
            >
                Personalised Tour
            </ClearButton>

            <section className={styles.user_menu}>
                <ClearButton
                    onClick={() => router.push("/about")}
                    visibleFrom="lg"
                >
                    About
                </ClearButton>
                <CurrencySelector />
                <HeaderGeoSearch />
                <ToggleTheme />
                <UserMenu />
            </section>
        </header>
    );
}
