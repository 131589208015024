import Link from "next/link";
import Instagram from "../icons/socials";
import { Tiktok, Whatsapp, Substack } from "../icons/socials";
import { mediumIconSize } from "../../utils/dataList/iconsize";
import styles from "./footer.module.css";

export default function Social() {
    return (
        <section className={styles.socialgroup}>
            <Link
                href="https://www.instagram.com/kodamatravel/"
                target="_blank"
            >
                <Instagram
                    width={mediumIconSize.width}
                    height={mediumIconSize.height}
                />
            </Link>
            <Link href="https://www.tiktok.com/@kodamatravel" target="_blank">
                <Tiktok
                    width={mediumIconSize.width}
                    height={mediumIconSize.height}
                />
            </Link>
            <Link href="https://wa.me/4407768804495" target="_blank">
                <Whatsapp
                    width={mediumIconSize.width}
                    height={mediumIconSize.height}
                />
            </Link>

            <Link href="https://substack.com/@kodamatravel" target="_blank">
                <Substack
                    width={mediumIconSize.width}
                    height={mediumIconSize.height}
                />
            </Link>
        </section>
    );
}
