"use client";
import { Text } from "@mantine/core";
import SearchGroup from "../search/SearchGroup";
import { colours } from "../../utils/dataList/colours";
import styles from "./hero.module.css";

export default function Hero() {
    return (
        <>
            <section className={styles.root}>
                <div className={styles.content}>
                    <h1 className={styles.title}>
                        Make{" "}
                        <Text
                            component="span"
                            inherit
                            variant="gradient"
                            gradient={{
                                from: `${colours.primary}`,
                                to: "red",
                                deg: 120,
                            }}
                        >
                            Responsible Travel
                        </Text>{" "}
                        the norm
                    </h1>

                    <h2 className={styles.description}>
                        {`We make it easy for you to book responsible travel experiences. Founded by conservationists, powered by ethical tour operators. Let's make travel force for good.`}
                    </h2>

                    <SearchGroup />
                </div>
            </section>
        </>
    );
}
