"use client";
import { useState, useEffect } from "react";
import { Modal } from "@mantine/core";
import { currencies } from "../../utils/dataList/currency";
import { ClearButton } from "../lego/Button";
import styles from "./header.module.css";

export default function CurrencySelector() {
    const [opened, setOpened] = useState(false);

    const [userCurrencyCode, setUserCurrencyCode] = useState<string>("GBP");
    const [userCurrencySign, setUserCurrencySign] = useState<string>("£");

    useEffect(() => {
        if (typeof window !== "undefined") {
            const storedCurrencyCode = localStorage.getItem("userCurrencyCode");
            const storedCurrencySign = localStorage.getItem("userCurrencySign");
            if (storedCurrencyCode) setUserCurrencyCode(storedCurrencyCode);
            if (storedCurrencySign) setUserCurrencySign(storedCurrencySign);
        }
    }, []);

    return (
        <>
            <Modal opened={opened} onClose={() => setOpened(false)}>
                <div className={styles.currencypopup}>
                    {currencies.map((currency) => (
                        <ClearButton
                            key={currency.currency}
                            className={styles.currencylabel}
                            onClick={() => {
                                setUserCurrencyCode(currency.code);
                                setUserCurrencySign(currency.sign);
                                if (typeof window !== "undefined") {
                                    localStorage.setItem(
                                        "userCurrencyCode",
                                        currency.code
                                    );
                                    localStorage.setItem(
                                        "userCurrencySign",
                                        currency.sign
                                    );
                                    setOpened(false);
                                    window.location.reload();
                                }
                            }}
                        >
                            {currency.code} ({currency.sign})
                        </ClearButton>
                    ))}
                </div>
            </Modal>
            <ClearButton onClick={() => setOpened(true)}>
                {userCurrencyCode}
            </ClearButton>
        </>
    );
}
