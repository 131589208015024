import Link from "next/link";
import styles from "./footer.module.css";

export default function MobileMenu() {
    return (
        <section className={styles.mobilemenu}>
            <Link href="/custom-tour" className={styles.mobileLink}>
                Tailor-made Tour
            </Link>
            <Link href="/blog" className={styles.mobileLink}>
                Blog
            </Link>
            <Link href="/about" className={styles.mobileLink}>
                About
            </Link>
            <Link href="/conservation" className={styles.mobileLink}>
                Conservation Mission
            </Link>
            <Link href="/help" className={styles.mobileLink}>
                Help
            </Link>
            <Link href="/contact" className={styles.mobileLink}>
                Contact
            </Link>
            <Link href="/supplier" className={styles.mobileLink}>
                Become a Supplier
            </Link>
            <Link href="/creator" className={styles.mobileLink}>
                Creator Community
            </Link>
            <Link href="/host" className={styles.mobileLink}>
                Host Programme
            </Link>
            <Link href="/wildlife-policy" className={styles.mobileLink}>
                Wildlife Policy
            </Link>
            <Link href="/privacy" className={styles.mobileLink}>
                Privacy
            </Link>
        </section>
    );
}
