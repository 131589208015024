"use client";
import { useRouter } from "next/navigation";
import { experienceType } from "../../utils/dataList/experienceType";
import styles from "./header.module.css";

export default function ExperienceModal() {
    const experiences = experienceType.flatMap((category) => category.types);
    const router = useRouter();
    return (
        <section className={styles.activityModal}>
            {experiences.map((item) => (
                <figure
                    key={item.title}
                    onClick={() => router.push(`/activity/${item.title}`)}
                    className={styles.activitycard}
                >
                    <div className={styles.experienceicon}>{item.img} </div>
                    <p className={styles.name}>{item.title}</p>
                </figure>
            ))}
        </section>
    );
}
