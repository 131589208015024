import { Button, ActionIcon, Badge } from "@mantine/core";
import styles from "./lego.module.css";

const PrimaryButton = ({ children, ...rest }: any) => (
    <button className={`${styles.button} ${styles.primary}`} {...rest}>
        {children}
    </button>
);

export default PrimaryButton;

const SecondaryFilledButton = ({ children, ...rest }: any) => (
    <button className={`${styles.button} ${styles.secondary}`} {...rest}>
        {children}
    </button>
);
export { SecondaryFilledButton };

const SecondaryOutlineButton = ({ children, ...rest }: any) => (
    <button className={`${styles.button} ${styles.secondaryOutline}`} {...rest}>
        {children}
    </button>
);
export { SecondaryOutlineButton };

const MobileBookingButton = ({ children, ...rest }: any) => (
    <button className={`${styles.button} ${styles.mobileBooking}`} {...rest}>
        {children}
    </button>
);
export { MobileBookingButton };

const AlertOutlineButton = ({ children, ...rest }: any) => (
    <button className={`${styles.button} ${styles.outlineAlert}`} {...rest}>
        {children}
    </button>
);
export { AlertOutlineButton };

const ClearButton = ({ children, ...rest }: any) => (
    <Button
        classNames={{
            root: `${styles.button} ${styles.clearbuttonroot}`,
            label: `${styles.clearbuttonlabel}`,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { ClearButton };

const SubtleButton = ({ children, ...rest }: any) => (
    <button className={`${styles.button} ${styles.subtle}`} {...rest}>
        {children}
    </button>
);
export { SubtleButton };

const ClearOutlineButton = ({ children, ...rest }: any) => (
    <button className={`${styles.button} ${styles.clearOutline}`} {...rest}>
        {children}
    </button>
);
export { ClearOutlineButton };

const OutlineIconButton = ({ children, ...rest }: any) => (
    <Button
        classNames={{
            root: `${styles.button} ${styles.iconbuttonroot}`,
            label: `${styles.iconbuttonlabel}`,
        }}
        {...rest}
    >
        {children}
    </Button>
);
export { OutlineIconButton };

const FilterActionButton = ({ children, ...rest }: any) => (
    <ActionIcon
        autoContrast
        variant="outline"
        size="lg"
        classNames={{
            root: styles.filterbuttonroot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);
export { FilterActionButton };

const ActionIconButtonKT = ({ children, ...rest }: any) => (
    <ActionIcon
        variant="subtle"
        classNames={{
            root: styles.actionbuttonroot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);
export { ActionIconButtonKT };

const ActionIconAlertButtonKT = ({ children, ...rest }: any) => (
    <ActionIcon
        variant="subtle"
        classNames={{
            root: styles.alertbuttonroot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);
export { ActionIconAlertButtonKT };

const CountActionIconKT = ({ children, ...rest }: any) => (
    <ActionIcon
        size="sm"
        classNames={{
            root: styles.countIconRoot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);

export { CountActionIconKT };

const FavActionIconButtonKT = ({ children, ...rest }: any) => (
    <ActionIcon
        color="#C91A52"
        classNames={{
            root: styles.favroot,
        }}
        {...rest}
    >
        {children}
    </ActionIcon>
);
export { FavActionIconButtonKT };

const BadgeKT = ({ children, ...rest }: any) => (
    <Badge
        color="#C91A52"
        classNames={{
            root: styles.badgeroot,
            label: styles.badgelabel,
        }}
        {...rest}
    >
        {children}
    </Badge>
);
export { BadgeKT };
