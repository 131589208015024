import axios from "axios";

export const getWishList = async (userEmail: string) => {
    const response = await axios.get(
        `/api/account/wishlist?userEmail=${userEmail}`
    );
    return response.data.wishlist;
};

export const getUserAccount = async (userEmail: string) => {
    const response = await axios.get(`/api/account?userEmail=${userEmail}`);
    return response.data.account;
};

export const getUserBooking = async (userEmail: string) => {
    const response = await axios.get(
        `/api/account/booking?userEmail=${userEmail}`
    );
    return response.data.userBooking;
};

export const getUserReward = async (userEmail: string) => {
    const response = await axios.get(
        `/api/account/reward?userEmail=${userEmail}`
    );
    return response.data.rewardData;
};

export const getSupplierListing = async (userEmail: string) => {
    const response = await axios.get(
        `/api/supplier/listing?userEmail=${userEmail}`
    );
    return response.data.supplierListing;
};

export const getSupplierAccount = async (userEmail: string) => {
    const response = await axios.get(
        `/api/supplier/account?userEmail=${userEmail}`
    );
    return response.data.supplierAccount;
};

export const getAdminData = async () => {
    const response = await axios.get(`/api/account/adminData`);
    return response.data.adminData;
};
