"use client";
import { useQuery } from "@tanstack/react-query";
import { getIndexCampaign } from "../../utils/apiFunctions/listingFunction";
import { SkeletonLoaderCardGroup } from "../lego/SkeletonLoader";
import ListingCardGroup from "../../components/listing/ListingCardGroup";
import styles from "./exp.module.css";

interface CampaignItem {
    campaign: string;
    description: string;
}

export default function ListingIndex() {
    const { isFetching, isError, data } = useQuery({
        queryKey: ["indexCampaign"],
        queryFn: () => getIndexCampaign(),
    });

    if (isFetching) return <SkeletonLoaderCardGroup />;
    if (isError) return null;

    return (
        <>
            {data.map((item: CampaignItem) => (
                <section
                    key={item.campaign}
                    className={styles.indexlistingStack}
                >
                    <h3 className={styles.sectionHeading}>{item.campaign}</h3>

                    {item.description ? (
                        <p className={styles.sectiondescription}>
                            {item.description}
                        </p>
                    ) : null}
                    <ListingCardGroup campaign={item.campaign} />
                </section>
            ))}
        </>
    );
}
