"use client";
import { useSession } from "next-auth/react";
import { useQuery } from "@tanstack/react-query";
import { getUserAccount } from "../../../utils/apiFunctions/accountFunction";
import { Avatar } from "@mantine/core";

export default function ProfileAvatar() {
    const { data: session, status } = useSession();
    const userEmail = session?.user?.email;

    const { isLoading, isError, data } = useQuery({
        queryKey: ["accounts", userEmail],
        queryFn: ({ queryKey }) => getUserAccount(queryKey[1] as string),
        enabled: status === "authenticated",
    });

    if (isLoading)
        return (
            <Avatar
                size="md"
                variant="outline"
                src="/icons/avatar.svg"
                aria-label="profile avatar"
            />
        );
    if (isError)
        return (
            <Avatar
                size="md"
                variant="outline"
                src="/icons/avatar.svg"
                aria-label="profile avatar"
            />
        );

    return (
        <Avatar
            size="md"
            variant="outline"
            aria-label="profile avatar"
            src={session?.user?.image || "/icons/avatar.svg"}
        />
    );
}
