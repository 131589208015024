import axios from "axios";
import { listingSlugs } from "../sitemap";

export const getAlsoLikeListing = async (
    created_by: string,
    product_code: string
) => {
    const response = await axios.get(
        `/api/listing/alsoLike?created_by=${created_by}&product_code=${product_code}`
    );
    console.log(response.data.alsoLikeListing);
    return response.data.alsoLikeListing;
};

export const getFavourite = async (userEmail: string) => {
    const response = await axios.get(
        `/api/account/favourite?userEmail=${userEmail}`
    );
    return response.data.favouriteListings;
};

export const currencyConversion = async (
    fromCurrencyString: string,
    toCurrencyString: string
) => {
    const response = await axios.get(
        `/api/currencyConversion?fromCurrencyString=${fromCurrencyString}&toCurrencyString=${toCurrencyString}`
    );
    return response.data.fxRates;
};

export const getIndexListing = async (campaign: string) => {
    const response = await axios.get(
        `/api/collection/campaignList?campaign=${campaign}`
    );
    return response.data.campaignListing;
};

export const getExperience = async (slug: string) => {
    const response = await axios.get(`/api/experience?slug=${slug}`);
    return response.data.exp;
};

export const getIndexCampaign = async () => {
    const response = await axios.get(`/api/collection/indexCampaign`);
    return response.data.indexCampaign;
};

export const getFXRates = async () => {
    const response = await axios.get(`/api/liveFXList`);
    return response.data.liveFXList;
};

export const getListingSlugs = async () => {
    const response = await axios.get(`/api/listingSlug`);
    return response.data.listingslugs;
};
