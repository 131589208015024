"use client";
import React, { useState } from "react";
import { ActionIcon, Modal } from "@mantine/core";
import MagGlass from "../icons/magGlass";
import SearchModal from "../search/SearchModal";
import { miniIconSize } from "../../utils/dataList/iconsize";

export default function HeaderGeoSearch() {
    const [opened, setOpened] = useState(false);
    return (
        <>
            <ActionIcon
                variant="default"
                radius="md"
                size="md"
                p="2px"
                aria-label="search icon"
                onClick={() => setOpened(true)}
            >
                <MagGlass
                    width={miniIconSize.width}
                    height={miniIconSize.height}
                    size={miniIconSize.size}
                />
            </ActionIcon>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                radius="15px"
                size="auto"
            >
                <SearchModal />
            </Modal>
        </>
    );
}
