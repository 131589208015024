import Link from "next/link";
import styles from "./footer.module.css";

export default function Menu() {
    return (
        <section className={styles.menugroup}>
            <div className={styles.menucard}>
                <p className={styles.heading}>Traveller</p>

                <Link href="/custom-tour" className={styles.link}>
                    Tailor-made Tour
                </Link>
                <Link href="/blog" className={styles.link}>
                    Blog
                </Link>
                <Link href="/help" className={styles.link}>
                    Help Centre
                </Link>
                <Link href="/contact" className={styles.link}>
                    Contact Us
                </Link>
            </div>
            <div className={styles.menucard}>
                <p className={styles.heading}>Partner</p>
                <Link href="/creator" className={styles.link}>
                    Creator Community
                </Link>
                <Link href="/supplier" className={styles.link}>
                    Become a Supplier
                </Link>
                <Link href="/host" className={styles.link}>
                    Become a Host Partner
                </Link>
            </div>
            <div className={styles.menucard}>
                <p className={styles.heading}>Kodama Travel</p>
                <Link href="/about" className={styles.link}>
                    About us
                </Link>
                <Link href="/conservation" className={styles.link}>
                    Conservation Mission
                </Link>
                <Link href="/wildlife-policy" className={styles.link}>
                    Wildlife Welfare Policy
                </Link>

                <Link href="/toc" className={styles.link}>
                    Terms of Services
                </Link>
                <Link href="/privacy" className={styles.link}>
                    Privacy Policy
                </Link>
            </div>
        </section>
    );
}
