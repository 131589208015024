"use client";
import { handleGoogleSignIn } from "./GoogleSignIn";
import { handleMagicSignIn } from "./MagicLink";
import { SecondaryFilledButton } from "../lego/Button";
import Image from "next/image";
import { Button, Divider } from "@mantine/core";
import TextInputKT from "../lego/TextInput";
import styles from "./header.module.css";

export default function SignIn() {
    return (
        <section className={styles.signinform}>
            <form action={handleMagicSignIn} className={styles.emailStack}>
                <TextInputKT
                    name="email"
                    placeholder="Email"
                    label="Email"
                    required={true}
                />
                <SecondaryFilledButton type="submit">
                    Sign up/ Log in with Email
                </SecondaryFilledButton>
            </form>

            <Divider labelPosition="center" my="lg" />
            <form action={handleGoogleSignIn} className={styles.google}>
                <Button
                    type="submit"
                    variant="outline"
                    classNames={{
                        root: styles.googleroot,
                        label: styles.googlelabel,
                    }}
                    leftSection={
                        <Image
                            src="/icons/google.svg"
                            alt="Google Logo"
                            width={20}
                            height={20}
                            unoptimized
                        />
                    }
                >
                    Sign in with Google
                </Button>
            </form>
        </section>
    );
}
